
import { defineComponent, ref, Ref } from "vue";
import { DropDownList as KDropDownList } from "@progress/kendo-vue-dropdowns";
import { SplitButton as KSplitButton } from "@progress/kendo-vue-buttons";
import {
  ExportValueOption,
  CommissionRateKind,
  ClientManagerGroup,
  DataTable,
  MONTH_LABELS,
  RevenueAndCommissionsItem,
} from "../typesAndConstants";

import * as helpers from "../helpers";
import * as api from "../api";
import { random } from "lodash-es";

const cache = new Map<string, any>();

export default defineComponent({
  name: "RevenueAndCommissionTab",
  components: {
    KDropDownList,
    KSplitButton,
  },
  props: {
    exportOptions: { type: Array, required: true },
    selectedExportOption: { type: Object, required: true },
    clientManagers: { type: Array, required: true },
    selectedClientManager: { type: Object, required: true },
    clientManagerType: { type: Number, required: true },
    dateRangeFrom: { type: Object, required: true },
    dateRangeTo: { type: Object, required: true },
  },
  emits: [
    "revenue-and-commission-year-changed",
    "revenue-and-commission-export-option-changed",
    "data-loading",
    "data-loaded",
  ],
  setup() {
    const data: Ref<DataTable> = ref({
      headers: [] as Array<string>,
      body: [] as Array<string[]>,
    });

    return {
      data,
    };
  },
  watch: {
    dateRangeFrom: {
      async handler(_n, _o) {
        this.data = await this.load();
      },
    },
    dateRangeTo: {
      async handler(_n, _o) {
        this.data = await this.load();
      },
    },
  },
  async mounted() {
    this.data = await this.load();
  },
  methods: {
    async getRevenueAndCommissions(): Promise<RevenueAndCommissionsItem[]> {
      const cacheKey = `data-${this.clientManagerType}`;

      //if (!cache.has(cacheKey)) {
      const data = await api.getRevenueAndCommissions(
        this.dateRangeFrom as Date,
        this.dateRangeTo as Date,
        this.clientManagerType,
      );
      console.info("getRevenueAndCommissions::data", data);
      cache.set(cacheKey, data);
      //}

      return cache.get(cacheKey);
    },
    async load(): Promise<DataTable> {
      const addMissingRateKinds = (record: any) => {
        const hasLteYr: boolean = record?.RevenueAndCommissionsGroups.some(
          (o: any) => o.CommissionRateKind == CommissionRateKind.HighRate,
        );
        const hasGtYr: boolean = record?.RevenueAndCommissionsGroups.some(
          (o: any) => o.CommissionRateKind == CommissionRateKind.LowRate,
        );

        // Add missing RevenueAndCommissionsGroups
        const missingRateKinds: CommissionRateKind[] = [];
        if (!hasLteYr) missingRateKinds.push(CommissionRateKind.HighRate);
        if (!hasGtYr) missingRateKinds.push(CommissionRateKind.LowRate);
        if (record?.SalesRepGroup != ClientManagerGroup.InHouse) {
          for (const missingRateKind of missingRateKinds) {
            const info = !record
              ? []
              : [
                  ...record.RevenueAndCommissionsGroups[0].RevenueAndCommissionsInfo.map(
                    (o: any) => ({
                      ...o,
                      CommissionableRevenue: 0,
                      Commissions: 0,
                    }),
                  ),
                ];

            const missingEntry = {
              CommissionRateKind: missingRateKind,
              CommissionRate: 0,
              RevenueAndCommissionsInfo: info,
            };
            record?.RevenueAndCommissionsGroups.push(missingEntry);
            record?.RevenueAndCommissionsGroups.sort(
              (a: any, b: any) => a.CommissionRateKind - b.CommissionRateKind,
            );
          }
        }

        return record;
      };

      const getRow = (ct: number, record: any) => {
        const salesRepGroup = helpers.strOfSalesRepGroup(record?.SalesRepGroup);
        const salesRep = record?.SalesRepFullName;
        const isOdd = ct % 2 == 0;

        return {
          isOdd,
          rowSpan: record?.RevenueAndCommissionsGroups.length,
          salesRepGroup,
          salesRep,
          items: record?.RevenueAndCommissionsGroups.map((group: any) => {
            const commissionType = helpers.strOfCommissionRateKind(
              group.CommissionRateKind,
            );
            const monthlyValues = [...Array(months.length)].fill(0);
            let idx = 0;
            for (const info of group.RevenueAndCommissionsInfo) {
              const value =
                this.selectedExportOption.value == ExportValueOption.Revenue
                  ? info.CommissionableRevenue
                  : info.Commissions;

              monthlyValues[idx] = value;
              idx++;
            }
            const total = monthlyValues.reduce(
              (value, total) => total + value,
              0,
            );
            const monthlyValuesWithtTotal = [total, ...monthlyValues];
            return [
              commissionType,
              ...monthlyValuesWithtTotal.map((value: any) =>
                helpers.moneyFmt(value),
              ),
            ];
          }),
        };
      };

      //---------------------------------------------------
      this.$emit("data-loading");
      const data = await this.getRevenueAndCommissions();
      const headers: string[] = [
        "Group",
        "Sales rep",
        "Commission type",
        "Total",
      ];
      if (!data) {
        this.$emit("data-loaded");
        return {
          headers,
          body: [],
        };
      }
      const months = helpers.pushMonthlyHeadersDataRange(
        headers,
        this.dateRangeFrom as Date,
        this.dateRangeTo as Date,
        MONTH_LABELS,
      );
      console.info("data", data);

      const body = data.map((record: any, ct: number) =>
        getRow(ct, addMissingRateKinds(record)),
      );
      // Flatten the structure for the HTML table
      const flatBody: any[] = [];
      for (const elem of body) {
        const items = [...elem.items];
        const firstItem = items.pop();
        flatBody.push([
          elem.isOdd,
          elem.items.length,
          elem.salesRepGroup,
          elem.salesRep,
          ...firstItem,
        ]);
        const rest = items.map((item: any) => [elem.isOdd, 0, "", "", ...item]);
        if (rest.length > 0) {
          flatBody.push(...rest);
        }
      }
      this.$emit("data-loaded");
      return {
        headers,
        body: flatBody,
      };
    },

    // #region Event Handlers
    async onYearChanged(event: any): Promise<void> {
      this.$emit("revenue-and-commission-year-changed", {
        value: event.value,
      });
      this.data = await this.load();
    },
    async onExportOptionChanged(event: any): Promise<void> {
      this.$emit("revenue-and-commission-export-option-changed", {
        value: event.value,
      });
      this.data = await this.load();
    },
    async onExportItemClicked(event: any): Promise<void> {
      const exportOption = event.item.value;

      const { fileName, mimeType, content } = helpers.getCsvInfo(
        await api.getRevenueAndCommissionsCsv(
          this.dateRangeFrom as Date,
          this.dateRangeTo as Date,
          exportOption,
          this.clientManagerType,
        ),
      );

      helpers.saveAsFile(content, mimeType, fileName);
    },
    // #endregion
  },
});
